import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const PressKitBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const H4Bps = css(
  mq({
    fontSize: [33.6],
    color: ['rgba(0, 0, 0, 0.87)'],
    fontWeight: [500],
    maxWidth: [328, '100%', 680, 680]
  })
)

export const marginHeading = css(
  mq({
    marginLeft: [0, 0, 200, 302]
  })
)

export const PBps = css(
  mq({
    fontSize: [14],
    maxWidth: [328, '100%', 680, 680],
    color: ['rgba(0, 0, 0, 0.6)']
  })
)

export const styledPressKit = css`
  ${PressKitBps};
`

export const headignContent = css`
  display: flex;
  h4 {
    ${H4Bps};
    ${marginHeading};
  }
  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    ${marginHeading};
  }
  p {
    ${marginHeading};
    ${PBps};
    line-height: 1.71;
    margin: 0 auto;
    margin-top: 8px;
  }
`
export const pressKitItems = css`
  margin: 0 auto;
  display: flex;
`
export const pressKitItemHeading = css`
  background: #f3f5f5;
  width: 328px;
  height: 192px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  span {
    font-size: 11.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 27px;
    margin-bottom: 27px;
  }
`
export const pressKitItemContent = css`
  width: 328px;
  height: 267px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  z-index: -1;
  margin-top: -124px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 44px;
  }
`

export const line = css`
  width: 50px;
  border: solid 1px #f3f5f5;
  background: #f3f5f5;
  height: 1px;
  margin-top: 34.2px;
  margin-bottom: 49.5px;
`